@import url(https://use.typekit.net/qlc2ljq.css);
.dashboard-timeline-item {
	padding: 6px 16px;
	width: 50%;
}

@media (max-width: 1024px) {
	.dashboard-title {
		text-align: center;
		padding: 0 20px;
		font-size: 30px;
	}

	.dashboard-timeline-item {
		width: 100%;
	}
}

.my-swal {
    z-index: 300000 !important;
}

.sweet-alert {
	z-index: 300000 !important;
}
.cta-caption {
  font-family: 'Saira Extra Condensed';
  font-style: normal;
  font-weight: 900;
  font-size: 80px;
  line-height: 90%;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 32px;
  margin-top: 60px;
}

.cta-caption span {
  color: #317BFA;
  display: block;
}

.cta-subcaption {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 176.69%;
  color: #FFFFFF;
}

.cta-benefits {
  color: #FFFFFF;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 176.69%;
  padding-top: 40px;
  padding-bottom: 210px;
  list-style: none;
}

.cta-benefits li {
  margin-bottom: 10px;
}

.cta-benefit-mark {
  vertical-align: middle;
  background-color: #580CE3;
  border-radius: 1em;
  padding: 1px;
  margin-right: 5px;
}

.offer-roadmap {
  padding-bottom: 50px;
  justify-content: space-between;
}

.offer-active-step {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
}

.offer-active-step span {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  background-color: #FFFFFF;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  padding: 3px 0;
  margin-top: 1px;
}


.offer-inactive-step {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #808080;
}

.offer-inactive-step span {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #808080;
  border: 1px solid #808080;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  padding: 2px 0;
  margin-top: 1px;
}

.offer-signup {
  padding-bottom: 30px;
}

.offer-block-caption-wrapper {
  text-align: center;
  padding-bottom: 30px;
}

.offer-block-caption {
  font-family: 'Saira Extra Condensed';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  text-transform: uppercase;
  color: #FFFFFF;
  border-bottom: 4px solid #580CE3;
  padding: 0 10px;
}

.offer-text {
  color: #FFFFFF;
}

a.offer-link {
  color: #FFFFFF;
  text-decoration: underline;
}

a.offer-link:hover {
  text-decoration: none;
}

.offer-input div {
  background-color: #FFFFFF;
}

.offer-input legend,
.offer-input label[data-shrink="true"] {
  display: none;
}

button.offer-button {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.115em;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #3E42F9;
  border-radius: 4px;
  padding: 12px 36px;
}

button.offer-button:disabled {
  color: rgba(255, 255, 255, 0.26);
  background: rgba(62, 66, 249, 0.91);
}

.offer-product {
  background: #0B115A;
  border: 5px solid #0B115A;
  border-radius: 16px;
  padding: 45px 30px;
  font-family: 'Roboto';
  font-style: normal;
  color: #C9C9C9;
  margin-bottom: 24px;
  cursor: pointer;
  position: relative;
}

.offer-product.special {
  border-color: #317BFA;
}

.offer-product-cta {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 8px 18px 8px 16px;
  grid-gap: 8px;
  gap: 8px;
  position: absolute;
  left: 0px;
  top: 0px;
  background: #317BFA;
  border-radius: 8px 0px 8px;
}

.offer-product.active,
.offer-product:hover {
  color: #FFFFFF;
}

.offer-product-name {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.offer-product-price {
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  padding-top: 16px;
  padding-bottom: 8px;
}

.offer-product-billed {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.offer-product-check-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}

.offer-product-check {
  background-color: #0B115A;
  color: #0B115A;
  width: 33px !important;
  height: 33px !important;
  border-radius: 33px;
  padding: 1px;
  border: 3px solid #C9C9C9;
}

.offer-product.active .offer-product-check {
  background-color: #317BFA;
  color: #FFFFFF;
  border: 3px solid #317BFA;
}

.offer-payment .stripe-card {
  background: none;
  padding-top: 15px;
}

.offer-payment .StripeElement {
  background: #FFFFFF;
  padding: 20px 10px;
  border-radius: 10px;
}

.offer-payment #payment-message {
  color: #FFFFFF;
  font-size: 13px;
  text-align: center;
  padding-top: 5px;
}

.offer-payment button {
  background: #3E42F9;
  border-radius: 4px;
  padding: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.115em;
  text-transform: uppercase;
}

.offer-payment-disclaimer {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  padding: 0 15px;
  margin-bottom: 50px;
}

.cta-welcome {
  background: #0B115A;
  border-radius: 16px;
  padding: 56px 40px;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
}

.cta-welcome-mark {
  background: #317BFA;
  color: #FFFFFF;
  width: 110px !important;
  height: 110px !important;
  border-radius: 110px;
  padding: 1px;
  margin-bottom: 46px;
}

.cta-welcome-caption {
  margin-bottom: 30px;
}

.cta-welcome-caption span {
  font-family: 'Saira Extra Condensed';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid #317BFA;
}

.cta-welcome-apps {
  margin-top: 30px;
}

.cta-welcome-apps a {
  margin: 0 15px;
}

.cta-total {
  font-family: 'Roboto';
  font-style: normal;
  color: #FFFFFF;
}

.cta-total-today {
  font-weight: 700;
  font-size: 24px;
  border-bottom: 1px solid #FFFFFF;
}

.cta-total-sum {
  text-align: right;
}

.cta-total-after {
  font-weight: 400;
  font-size: 18px;
  color: #C9C9C9;
  padding-top: 10px;
}

.cta-mobile-stepper {
  display: none !important;
}

.offer-section {
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 1128px) {
  .cta-welcome {
    background: #06092F;
    padding: 6px 10px;
  }

  .cta-welcome-mark {
    margin-top: 25px;
  }

  .cta-welcome-caption span {
    font-family: 'Roboto';
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    border: none;
    text-transform: none;
  }

  .cta-welcome-apps a {
    margin: 0 5px;
  }

  .cta-welcome-apps img {
    width: 140px;
  }

  .cta-mobile-stepper {
    display: flex !important;
    background-color: transparent !important;
    padding: 24px 0px !important;
  }

  .cta-mobile-stepper svg {
    color: #FFFFFF !important;
  }

  .cta-mobile-stepper text {
    fill: #06092F !important;
    font-weight: 700;
    font-size: 13px !important;
  }

  .cta-mobile-stepper .inactive svg {
    color: #06092F !important;
    border: 1px solid #808080;
    border-radius: 50px;
  }

  .cta-mobile-stepper .inactive text {
    fill: #808080 !important;
    font-weight: 700;
    font-size: 13px !important;
  }

  .cta-caption {
    font-size: 42px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .cta-caption span {
    display: inline-block;
    margin-left: 5px;
  }

  .offer-roadmap,
  .offer-block-caption,
  .cta-subcaption,
  .cta-benefits,
  .cta-total {
    display: none !important;
  }

  .offer-product {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .offer-product.special {
    padding-top: 45px;
  }

  .offer-product-name {
    font-size: 14px;
  }

  .offer-product-price {
    font-size: 24px;
    padding: 0;
  }

  .offer-product-billed {
    font-size: 14px;
  }

  .offer-block-caption-wrapper {
    padding-bottom: 0;
  }

  .offer-section {
    padding-left: 8px;
    padding-right: 8px;
  }
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.user-info__promo-wrapper {
    width: 20%;
    display: inline-block;
    margin-top: 10px;
}

@media (max-width: 1024px) {
    .user-info__promo-wrapper {
        width: 60%;
    }
}

.my-swal {
    z-index: 300000 !important;
}
#title {
  text-align: center;
  font-family: arial, sans-serif;
}

#table {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 100%;
}

#table td, #table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#table tr:nth-child(even){background-color: #f2f2f2;}
#table tr:nth-child(4){background-color: #000C66; color: white}

#table tr:hover {background-color: #ddd;}
#table tr:nth-child(4):hover{background-color: #000C66; color: white}

#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #000C66;
  color: white;
}

/*.billed-selector-top {
  display: none;
  padding-top: 35px;
  font-size: 15px;
  text-align: center;
}

.mobile-view {
  display: none !important;
}*/

@media (max-width: 1024px) {
  /*.billed-selector-table,
  .start-or-purchase,
  .desktop-view {
    display: none;
  }

  .mobile-view {
    display: block !important;
  }*/

  .caption-wrapper {
    max-width: calc(100vw - 20px);
    margin-left: 30px;
  }

  .table-wrapper {
    overflow-x: auto;
    max-width: calc(100vw - 20px);
    margin-left: 30px;
  }
}

.my-swal {
  z-index: 300000 !important;
}

li {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.plans .plans-banner {
  background: url("/images/banners/banner.jpeg");
  background-size: cover;
  min-height: 650px;
}

.plans .plans-banner.players {
  background: linear-gradient(45deg, #101828 0%, #475467 100%);
}

.plans .plans-banner-caption {
  padding-top: 96px;
  padding-left: 112px;
  font-weight: 400;
  font-size: 60px;
  line-height: 72px;
  color: #ffffff;
}

.plans .plans-banner-subcaption {
  padding-top: 24px;
  padding-bottom: 48px;
  padding-left: 112px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}

.plans .plans-banner-testimonials {
  background: url("/images/banners/D-A1.svg") no-repeat;
  background-position: 40px 90px;
  background-size: 80%;
  height: 650px;
}

.plans .plans-banner.players .plans-banner-testimonials {
  background: url("/images/banners/D-A2.svg") no-repeat;
  background-position: 40px 90px;
  background-size: 80%;
}

.plans .plans-banner-buttons {
  padding-bottom: 56px;
  padding-left: 112px;
}

.plans .plans-selector-wrapper {
  text-align: center;
  padding-top: 32px;
}

.plans .plans-selector-group {
  background: #f9fafb;
  border: 1px solid #f2f4f7;
  border-radius: 8px;
  padding: 6px;
  margin-bottom: 40px;
}

.plans .plans-selector-group button {
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  border: none;
  background: none;
  text-transform: none;
  margin: 0 5px;
}

.plans .plans-selector-group button[aria-pressed="true"] {
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  color: #344054;
}

.plans .plans-period-wrapper {
  text-align: center;
  margin-bottom: 48px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.plans .plans-switch-wrapper {
  text-align: left;
  color: #344054;
  margin-top: auto;
  display: flex;
}

.plans .plans-period-switch {
  padding: 6px;
  margin: 0 14px;
}

.plans .plans-period-switch > span:first-child {
  color: #ffffff;
}

.plans .plans-period-switch > span:last-child {
  opacity: 0.38;
  background-color: #000;
  border-radius: 20px;
}

.plans .plans-period-wrapper .Mui-checked + span:last-child {
  background: #4009a5;
  opacity: 1;
}

.plans .plans-switch-wrapper .Mui-checked + span:last-child {
  background: #4009a5;
  opacity: 1;
}

.plans .plan-card {
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 16px;
  margin: 0 16px;
  padding: 32px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  height: 100%;
}

.plans .add-on-card {
  height: auto;
  padding: 12px 8px 12px 8px;
  border-radius: 8px;
  border: 2px solid #30077c;
  background: #ffffff;
  font-size: 14px;
  margin-bottom: 15px;
}

.plans .add-on-card > span {
  background: transparent;
  border: 1.5px solid #4009a5;
  padding: 4px 12px;
  border-radius: 16px;
  color: #30077c;
}

.plans .plan-card-boot {
  background: #1d2939;
  color: #ffffff;
}

.plans .plan-card-caption {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

.plans .plan-card-caption span {
  background: #f9f5ff;
  mix-blend-mode: multiply;
  border-radius: 16px;
  padding: 4px 12px;
  float: right;
}

.plans .plan-card-boot .plan-card-caption span {
  border: 2px solid #ee46bc;
  color: #ffffff;
  mix-blend-mode: initial;
  background: none;
}

.plans .plan-card-price {
  margin-bottom: 16px;
}

.plans .plan-card-price span {
  font-weight: 400;
  font-size: 53px;
  line-height: 72px;
  color: #101828;
}

.plans .plan-card-boot .plan-card-price span {
  color: #ffffff;
}

.plans .plan-card-boot .with-3d {
  display: block;
}

.plans .plan-card-features-caption {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  margin-top: 32px;
  margin-bottom: 4px;
}

.plans .plan-card-boot .plan-card-features-caption {
  color: #ffffff;
}

.plans .plan-card-features {
  margin-top: 24px;
  list-style: none;
}

.plans .plan-card-features li {
  margin-bottom: 16px;
  display: flex;
}

.plans .plan-card-boot .plan-card-features li span {
  color: #6ce9a6;
}

.plans .plan-card-boot .plan-card-features li s {
  color: #667085;
}

.plans .plan-card-feature-mark {
  border: 2px solid #4009a5;
  border-radius: 25px;
  color: #4009a5;
  vertical-align: middle;
  margin-right: 10px;
}

.plans .plan-card-boot .plan-card-feature-mark {
  border: 2px solid #6ce9a6;
  color: #6ce9a6;
}

.plans .plan-button {
  padding: 12px 20px;
  width: 100%;
  font-weight: 600;
  background: #5c0dee;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin: 32px 0;
  color: #ffffff;
  text-transform: none;
}

.plans .plan-button:hover {
  background: #3c1585;
}

.plans .plan-button-outlined {
  padding: 12px 20px;
  width: 100%;
  font-weight: 600;
  height: 48px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #344054;
  margin: 32px 0;
  text-transform: none;
}

.plans .plans-banner-buttons .plan-button {
  width: auto;
}

.plans .plan-compare-caption {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #101828;
  margin-top: 128px;
  margin-bottom: 64px;
}

.plans .plan-compare-table {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}

.plans .plan-compare-features {
  font-weight: 500;
  color: #101828;
  border-top: 2px solid transparent;
}

.plans .plan-compare-features .plan-compare-rows > div {
  height: 64px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  align-items: center;
}

.plans .plan-compare-row {
  background: #f9fafb;
}

.plans .plan-compare-features .plan-compare-features-section,
.plans .plan-compare .plan-compare-features-section {
  color: #9662f7;
  font-weight: 600;
  padding-top: 24px;
  height: 88px;
}

.plans .plan-compare {
  border-radius: 16px;
  border-top: 2px solid transparent;
  padding-bottom: 16px;
}

.plans .plan-compare:hover {
  border: 2px solid #5c0dee;
}

.plans .plan-compare:hover .plan-compare-row {
  background: #f9f5ff;
}

.plans .plan-compare .plan-compare-rows > div {
  height: 64px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.plans .plan-compare .plan-compare-rows .plan-compare-row-double {
  height: 128px;
}

.plans .plan-compare .plan-card-feature-mark {
  margin: 0;
  border: 2px solid #039855;
  color: #039855;
}

.plans .plan-compare-header {
  position: -webkit-sticky;
  position: sticky;
  text-align: center;
  min-height: 305px;
  padding-top: 24px;
  top: 0;
  background: #ffffff;
  border-radius: 16px 16px 0 0;
}

.plans .plan-compare-header-caption {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #5c0dee;
  margin-bottom: 8px;
}

.plans .plan-compare-header-name {
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  color: #101828;
  margin-bottom: 8px;
}

.plans .plan-compare-header .plan-button,
.plans .plan-compare-header .plan-button-outlined {
  margin: 32px 24px;
  width: 200px;
}

.plans .plans-testimonials {
  margin-top: 80px;
  margin-bottom: 90px;
  height: 600px;
}

.plans .plans-testimonials > div {
  height: 100%;
}

.plans .plans-testimonials-body {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  background: #24055d;
  padding: 64px;
  color: #ffffff;
  height: 100%;
  position: relative;
}

.plans .plans-testimonials-stars {
  padding-bottom: 24px;
}

.plans .plans-testimonials-star {
  color: #fec84b;
  margin-right: 4px;
}

.plans .plans-testimonials-text {
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 32px;
}

.plans .plans-testimonials-photo {
  background: url("/images/testimonials/sean.jpeg") no-repeat center center;
  background-size: cover;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}

.plans .plans-testimonials-photo.coach2 {
  background: url("/images/testimonials/erika.jpeg") no-repeat center center;
  background-size: cover;
}

.plans .plans-testimonials-photo.coach3 {
  background: url("/images/testimonials/david.png") no-repeat center center;
  background-size: cover;
}

.plans .plans-testimonials-photo.coach4 {
  background: url("/images/testimonials/mike.jpeg") no-repeat center center;
  background-size: cover;
}

.plans .plans-testimonials-photo.player1 {
  background: url("/images/testimonials/winnie.jpeg") no-repeat center center;
  background-size: cover;
}

.plans .plans-testimonials-photo.player2 {
  background: url("/images/testimonials/caden.png") no-repeat center center;
  background-size: cover;
}

.plans .plans-testimonials-photo.player3 {
  background: url("/images/testimonials/glenn.png") no-repeat center center;
  background-size: cover;
}

.plans .plans-testimonials-photo.player4 {
  background: url("/images/testimonials/alexis.png") no-repeat center center;
  background-size: cover;
}

.plans .plans-testimonials-selectors {
  padding-top: 32px;
  position: absolute;
  bottom: 64px;
}

.plans .plans-testimonials-selectors button {
  width: 10px;
  height: 10px;
  margin-right: 16px;
  background: #5c0dee;
  border-radius: 6px;
  padding: 0;
  min-width: 10px;
}

.plans .plans-testimonials-selectors button.active {
  background: #ffffff;
}

.plans .plans-checkout-cta {
  background: url("/images/bg_checkout.png");
  color: #ffffff;
  padding: 152px 96px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.plans .plans-checkout-cta-caption {
  font-weight: 400;
  font-size: 60px;
  line-height: 72px;
}

.plans .plans-checkout-cta-description {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-top: 24px;
  color: #e9ddfd;
}

.plans .plans-checkout-cta-coaches {
  margin-top: 32px;
  padding-left: 25px;
  display: flex;
  align-items: center;
}

.plans .plans-checkout-cta-coaches img {
  position: relative;
  margin-left: -25px;
  margin-right: 10px;
}

.plans .plans-checkout {
  padding-top: 160px;
  padding-bottom: 40px;
}

.plans .plans-step-back {
  padding-bottom: 42px;
}

.plans .plans-step-back a {
  color: #000000;
}

.plans .plans-signup-caption {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #9662f7;
  margin-bottom: 16px;
  padding-left: 10px;
}

.plans .plans-signup-caption-trial {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #5a05f8;
  margin-bottom: 16px;
  padding-left: 10px;
}

.plans .plans-signup-form-element {
  margin-bottom: 20px;
  padding: 0 10px;
}

.plans .plans-signup-form-element fieldset {
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.plans .plans-signup-form-element .plan-button {
  margin: 0;
}

.plans .plans-checkout-progress {
  text-align: center;
  margin-top: 35px;
}

.plans .plans-checkout-progress.mobile {
  display: none;
}

.plans .plans-checkout-progress span {
  width: 43px;
  height: 8px;
  background: #f2f4f7;
  mix-blend-mode: multiply;
  border-radius: 4px;
  margin: 0 6px;
  display: inline-block;
}

.plans .plans-checkout-progress span.plans-checkout-progress-done {
  background-color: #30077c;
}

.plans .plans-summary-caption {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}

.plans .plans-summary-row {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  margin-top: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid #d0d5dd;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plans .plans-summary-row span {
  font-weight: 600;
  color: #000000;
}

.plans-payment-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-top: 12px;
  padding-bottom: 32px;
  color: #475467;
}

.plans-payment-caption {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #9662f7;
}

.plan-success-mark {
  border-radius: 25px;
  color: #5c0dee;
  background: #e9ddfd;
  vertical-align: middle;
  padding: 6px;
  width: 1.8em !important;
  height: 1.8em !important;
  margin-top: 150px;
  margin-bottom: 38px;
}

.plan-success-caption {
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}

.plan-success-subcaption {
  padding-top: 12px;
  padding-bottom: 32px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #475467;
}

.plan-success-apps {
  text-align: center;
}

.plan-success-apps a {
  margin: 0 15px;
}

.plans .plan-compare-mobile {
  display: none;
}

#mobile-menu h5 {
  padding-left: 15px;
  padding-top: 20px;
  padding-bottom: 7px;
}

@media (min-width: 960px) and (max-width: 1180px) {
  .plans .plan-card {
    margin: 0 10px;
    padding: 20px;
  }

  .plans .plan-card-caption {
    font-size: 15px;
    line-height: 25px;
  }

  .plans .plan-card-caption span {
    border-radius: 16px;
    padding: 2px 9px;
    line-height: 18px;
  }
  .plans .plan-card-price span {
    font-size: 41px;
    line-height: 52px;
  }

  .plans .plan-card-boot .with-3d {
    display: inline-block;
  }
}

@media (max-width: 960px) {
  .plans .plan-card {
    margin-bottom: 16px;
    height: auto;
  }
}

@media (max-width: 1128px) {
  .plans .plans-checkout {
    padding-left: 20px;
    padding-right: 20px;
  }

  .plans .plans-checkout-cta {
    display: none;
  }

  .plans .plans-checkout-progress {
    display: none;
  }

  .plans .plans-checkout-progress.mobile {
    display: block;
    margin-bottom: 24px;
  }

  .plans .plan-compare-mobile {
    display: block;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #eaecf0;
  }

  .plans .plan-compare-mobile button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    color: #667085;
    margin: 0 5px;
  }

  .plans .plan-compare-mobile button.selected {
    background: #f9f5ff;
    border-radius: 6px;
    color: #6941c6;
  }

  .plans .plan-compare {
    display: none;
  }

  .plans .plan-compare.selected {
    display: block;
  }

  .plans .plan-compare-header {
    display: none;
  }

  .plans .plan-compare-caption {
    margin-bottom: 20px;
  }

  .plans .plan-compare .plan-compare-rows > div,
  .plans .plan-compare-features .plan-compare-rows > div {
    height: 80px;
  }

  .plans .plan-compare .plan-compare-rows > .plan-compare-row-double,
  .plans .plan-compare-features .plan-compare-rows > .plan-compare-row-double {
    height: 158px;
  }

  .plans .plan-compare .plan-compare-rows .plan-compare-row-double {
    height: 158px;
  }

  .plans .plan-compare:hover .plan-compare-row:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .plans .plans-banner {
    min-height: auto;
  }

  .plans .plans-banner-caption {
    padding: 16px;
    font-size: 36px;
    line-height: 44px;
    padding-top: 146px;
  }

  .plans .plans-banner-subcaption {
    padding: 16px;
    font-size: 18px;
    line-height: 28px;
  }

  .plans .plans-banner-buttons {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 15px;
    padding-bottom: 20px;
  }

  .plans .plans-banner-buttons .plan-button {
    width: 100%;
    margin-top: 0;
    margin-bottom: 15px;
  }

  .plans .plans-banner-testimonials {
    background: url("/images/banners/D-A1.svg") no-repeat;
    background-position: 40px 90px;
    background-size: 80%;
    height: 650px;
  }

  .plans .plans-banner.players .plans-banner-testimonials {
    background: url("/images/banners/D-A2.svg") no-repeat;
    background-position: 40px 90px;
    background-size: 80%;
  }

  .plans .plans-banner-testimonials {
    min-height: 370px;
    background: url("/images/banners/M-A1.svg") no-repeat;
    background-size: contain;
    height: auto;
    background-position: center center;
  }

  .plans .plans-banner.players .plans-banner-testimonials {
    background: url("/images/banners/M-A2.svg") no-repeat;
    background-size: contain;
    background-position: center center;
  }

  .plans .plans-testimonials,
  .plans .plans-testimonials > div {
    height: auto;
  }

  .plans .plans-testimonials-body {
    border-top-right-radius: 24px;
    border-bottom-left-radius: 0;
    padding: 40px 24px;
  }

  .plans .plans-testimonials-text {
    font-size: 24px;
    line-height: 32px;
  }

  .plans .plans-testimonials-photo {
    border-top-right-radius: 0;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
    min-height: 310px;
  }

  .plans .plans-testimonials-selectors {
    position: static;
  }
}

.plan-card-description {
  height: 48px;
}

.plan-card-button-unified-container {
  height: 182px;
  /* height: auto; */
}

.styles_container__4tRKh {
  padding: 50px 100px;
}

.styles_bold__2Scgg {
  font-weight: bold;
}

.styles_centered__qHu-Y {
  text-align: center;
}

.styles_flexCentered__CV2DH {
  display: flex;
  justify-content: center;
}

.styles_italic__3OJTT {
  font-style: italic;
}

.styles_boldUnderline__a3RUq {
  font-weight: bold;
  text-decoration: underline;
}

.styles_spacer__3pLox {
  height: 25px;
}

.styles_firstIndent__3p37U {
  margin-left: 25px;
  margin-top: 25px;
}

.styles_secondIndent__GjHId {
  margin-left: 50px;
  margin-top: 25px;
}

.styles_preWrap__3MayC {
  white-space: pre-wrap;
}

.my-swal {
    z-index: 300000 !important;
}

.cta-caption {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 900;
  font-size: 80px;
  line-height: 90%;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 32px;
  margin-top: 60px;
}

.cta-caption span {
  color: #317BFA;
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
}

.cta-subcaption {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 176.69%;
  color: #FFFFFF;
}

.cta-invitational {
  padding-top: 50px;
}

.cta-golfpass-benefits-wrapper {
  padding-bottom: 140px;
}

.cta-golfpass-benefits {
  color: #FFFFFF;
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 176.69%;
  padding-top: 40px;  
  list-style: none;
}

.cta-golfpass-benefits li {
  margin-bottom: 10px;
}

.cta-benefit-mark {
  vertical-align: middle;
  background-color: #580CE3;
  border-radius: 1em;
  padding: 1px;
  margin-right: 5px;
}

.offer-roadmap {
  padding-bottom: 50px;
  justify-content: space-between;
}

.offer-active-step {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
}

.offer-active-step span {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  background-color: #FFFFFF;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  padding: 3px 0;
  margin-top: 1px;
}


.offer-inactive-step {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #808080;
}

.offer-inactive-step span {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #808080;
  border: 1px solid #808080;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  padding: 2px 0;
  margin-top: 1px;
}

.offer-signup {
  padding-bottom: 30px;
}

.offer-block-caption-wrapper {
  text-align: center;
  padding-bottom: 30px;
}

.offer-block-caption {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  text-transform: uppercase;
  color: #FFFFFF;
  border-bottom: 4px solid #580CE3;
  padding: 0 10px;
}

.offer-text {
  color: #FFFFFF;
}

a.offer-link {
  color: #FFFFFF;
  text-decoration: underline;
}

a.offer-link:hover {
  text-decoration: none;
}

.offer-input div {
  background-color: #FFFFFF;
}

.offer-input legend,
.offer-input label[data-shrink="true"] {
  display: none;
}

button.offer-button {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.115em;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #3E42F9;
  border-radius: 4px;
  padding: 12px 36px;
}

button.offer-button:disabled {
  color: rgba(255, 255, 255, 0.26);
  background: rgba(62, 66, 249, 0.91);
}

.offer-product {
  background: #0B115A;
  border: 5px solid #0B115A;
  border-radius: 16px;
  padding: 45px 30px;
  font-family: 'Anton';
  font-style: normal;
  color: #C9C9C9;
  margin-bottom: 24px;
  cursor: pointer;
  position: relative;
}

.offer-product.special {
  border-color: #317BFA;
}

.offer-product-cta {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 8px 18px 8px 16px;
  grid-gap: 8px;
  gap: 8px;
  position: absolute;
  left: 0px;
  top: 0px;
  background: #317BFA;
  border-radius: 8px 0px 8px;
}

.offer-product.active,
.offer-product:hover {
  color: #FFFFFF;
}

.offer-product-name {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.offer-product-price {
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  padding-top: 16px;
  padding-bottom: 8px;
}

.offer-product-billed {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.offer-product-check-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}

.offer-product-check {
  background-color: #0B115A;
  color: #0B115A;
  width: 33px !important;
  height: 33px !important;
  border-radius: 33px;
  padding: 1px;
  border: 3px solid #C9C9C9;
}

.offer-product.active .offer-product-check {
  background-color: #317BFA;
  color: #FFFFFF;
  border: 3px solid #317BFA;
}

.offer-payment .stripe-card {
  background: none;
  padding-top: 15px;
}

.offer-payment .StripeElement {
  background: #FFFFFF;
  padding: 20px 10px;
  border-radius: 10px;
}

.offer-payment #payment-message {
  color: #FFFFFF;
  font-size: 13px;
  text-align: center;
  padding-top: 5px;
}

.offer-payment button {
  background: #3E42F9;
  border-radius: 4px;
  padding: 10px;
  font-family: 'Anton';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.115em;
  text-transform: uppercase;
}

.offer-payment-disclaimer {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  padding: 0 15px;
  margin-bottom: 50px;
  margin-top: 5px;
}

.cta-welcome {
  background: #0B115A;
  border-radius: 16px;
  padding: 56px 40px;
  text-align: center;
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
}

.cta-welcome-mark {
  background: #317BFA;
  color: #FFFFFF;
  width: 110px !important;
  height: 110px !important;
  border-radius: 110px;
  padding: 1px;
  margin-bottom: 46px;
}

.cta-welcome-caption {
  margin-bottom: 30px;
}

.cta-welcome-caption span {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid #317BFA;
}

.cta-welcome-apps {
  margin-top: 30px;
}

.cta-welcome-apps a {
  margin: 0 15px;
}

.cta-total {
  font-family: 'Anton';
  font-style: normal;
  color: #FFFFFF;
}

.cta-total-today {
  font-weight: 700;
  font-size: 24px;
  border-bottom: 1px solid #FFFFFF;
}

.cta-total-sum {
  text-align: right;
}

.cta-total-after {
  font-weight: 400;
  font-size: 18px;
  color: #C9C9C9;
  padding-top: 10px;
}

.cta-mobile-stepper {
  display: none !important;
}

.offer-section {
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 1128px) {
  .cta-welcome {
    background: #06092F;
    padding: 6px 10px;
  }

  .cta-welcome-mark {
    margin-top: 25px;
  }

  .cta-welcome-caption span {
    font-family: 'Anton';
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    border: none;
    text-transform: none;
  }

  .cta-welcome-apps a {
    margin: 0 5px;
  }

  .cta-welcome-apps img {
    width: 140px;
  }

  .cta-mobile-stepper {
    display: flex !important;
    background-color: transparent !important;
    padding: 24px 0px !important;
  }

  .cta-mobile-stepper svg {
    color: #FFFFFF !important;
  }

  .cta-mobile-stepper text {
    fill: #06092F !important;
    font-weight: 700;
    font-size: 13px !important;
  }

  .cta-mobile-stepper .inactive svg {
    color: #06092F !important;
    border: 1px solid #808080;
    border-radius: 50px;
  }

  .cta-mobile-stepper .inactive text {
    fill: #808080 !important;
    font-weight: 700;
    font-size: 13px !important;
  }

  .cta-caption {
    font-size: 42px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .cta-caption span {
    display: inline-block;
    margin-left: 5px;
  }

  .cta-golfpass-benefits-wrapper {
    padding-bottom: 0;
  }

  .cta-subcaption {
    text-align: center;
  }

  .offer-roadmap,
  .offer-block-caption,
  .cta-subcaption,
  .cta-golfpass-benefits,
  .cta-total {
    display: none !important;
  }

  .cta-invitational {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .cta-invitational img {
    max-width: 100%;
  }

  .offer-product {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .offer-product.special {
    padding-top: 45px;
  }

  .offer-product-name {
    font-size: 14px;
  }

  .offer-product-price {
    font-size: 24px;
    padding: 0;
  }

  .offer-product-billed {
    font-size: 14px;
  }

  .offer-block-caption-wrapper {
    padding-bottom: 0;
  }

  .offer-section {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.styles_inputContainer__204Ja {
  border: #d0d5dd 1px solid;
  background-color: white;
  border-radius: 7px;
  color: #667085;
  display: flex;
  align-items: center;
  padding: 2px 10px;
  grid-gap: 10px;
  gap: 10px;
  box-sizing: border-box;
}

.styles_inputContainer__204Ja input {
  border: none;
  flex-grow: 1;
  width: 100%;
  outline: none;
  height: 14px;
  padding: 14px 0;
  background: rgba(0, 0, 0, 0);
}

.styles_inputContainer__204Ja div {
  margin-top: 5px;
  cursor: pointer;
}

.styles_inputLabel__1qiBE {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.styles_dashboardButton__DitfN {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}

.styles_dashboardButton__DitfN:disabled {
  background-color: #f2f4f7;
  color: gray;
  cursor: not-allowed;
}

.styles_blue__2wWNO {
  background-color: #4c59cc;
  color: white;
}

.styles_gray__BzISW {
  background-color: #f2f4f7;
  color: black;
}

.styles_purple__kCjDD {
  background-color: #5c0dee;
  color: white;
}

.styles_dashboardButton__DitfN.styles_fit__nVIud {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.styles_container__3vUA0 {
  display: flex;
  flex-direction: column;
}

.styles_selectWrapper__1q7cx {
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  border: 1px solid #d0d5dd;
  border-radius: 7px;
  padding: 0 12px;
  background-color: white;
  cursor: pointer;
  font-size: 16px;
}

.styles_selectValue__3godU {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.styles_selectValue__3godU span {
  font-size: 14px;
}

.styles_selectLabel__gyao1 {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

/* .options {
  position: absolute;
  top: 100%;
  left: 0;

  width: 100%;
  font-size: 14px;
  background-color: white;
  border: 1px solid #d0d5dd;
  border-radius: 7px;
  z-index: 100000000000 !important;
  display: none;
  margin-top: 4px;
  max-height: 300px;
  overflow-y: auto;
} */

.styles_options__29l9d {
  width: 100%;
}

.styles_optionsVisible__3-Yd3 {
  display: block;
}

.styles_option__iWC3E {
  padding: 8px 12px;
  cursor: pointer;
}

.styles_option__iWC3E:hover {
  background-color: #f0f0f0;
}

.styles_inputLabel__17BWk {
  color: #344054;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.cta-caption {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 900;
  font-size: 80px;
  line-height: 90%;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 32px;
  margin-top: 60px;
}

.cta-caption span {
  color: #317BFA;
  display: inline-block;
  margin-right: 15px;
  margin-top: 15px;
}

.cta-subcaption {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 176.69%;
  color: #FFFFFF;
}

.cta-benefits {
  color: #FFFFFF;
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 176.69%;
  padding-top: 40px;
  padding-bottom: 210px;
  list-style: none;
}

.cta-benefits li {
  margin-bottom: 10px;
}

.cta-benefit-mark {
  vertical-align: middle;
  background-color: #580CE3;
  border-radius: 1em;
  padding: 1px;
  margin-right: 5px;
}

.offer-roadmap {
  padding-bottom: 50px;
  justify-content: space-between;
}

.offer-active-step {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
}

.offer-active-step span {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  background-color: #FFFFFF;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  padding: 3px 0;
  margin-top: 1px;
}


.offer-inactive-step {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #808080;
}

.offer-inactive-step span {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #808080;
  border: 1px solid #808080;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  padding: 2px 0;
  margin-top: 1px;
}

.offer-signup {
  padding-bottom: 30px;
}

.offer-block-caption-wrapper {
  text-align: center;
  padding-bottom: 30px;
}

.offer-block-caption {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  text-transform: uppercase;
  color: #FFFFFF;
  border-bottom: 4px solid #580CE3;
  padding: 0 10px;
}

.offer-text {
  color: #FFFFFF;
}

a.offer-link {
  color: #FFFFFF;
  text-decoration: underline;
}

a.offer-link:hover {
  text-decoration: none;
}

.offer-input div {
  background-color: #FFFFFF;
}

.offer-input legend,
.offer-input label[data-shrink="true"] {
  display: none;
}

button.offer-button {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.115em;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #3E42F9;
  border-radius: 4px;
  padding: 12px 36px;
}

button.offer-button:disabled {
  color: rgba(255, 255, 255, 0.26);
  background: rgba(62, 66, 249, 0.91);
}

.offer-product {
  background: #0B115A;
  border: 5px solid #0B115A;
  border-radius: 16px;
  padding: 45px 30px;
  font-family: 'Anton';
  font-style: normal;
  color: #C9C9C9;
  margin-bottom: 24px;
  cursor: pointer;
  position: relative;
}

.offer-product.special {
  border-color: #317BFA;
}

.offer-product-cta {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 8px 18px 8px 16px;
  grid-gap: 8px;
  gap: 8px;
  position: absolute;
  left: 0px;
  top: 0px;
  background: #317BFA;
  border-radius: 8px 0px 8px;
}

.offer-product.active,
.offer-product:hover {
  color: #FFFFFF;
}

.offer-product-name {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.offer-product-price {
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  padding-top: 16px;
  padding-bottom: 8px;
}

.offer-product-billed {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.offer-product-check-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}

.offer-product-check {
  background-color: #0B115A;
  color: #0B115A;
  width: 33px !important;
  height: 33px !important;
  border-radius: 33px;
  padding: 1px;
  border: 3px solid #C9C9C9;
}

.offer-product.active .offer-product-check {
  background-color: #317BFA;
  color: #FFFFFF;
  border: 3px solid #317BFA;
}

.offer-payment .stripe-card {
  background: none;
  padding-top: 15px;
}

.offer-payment .StripeElement {
  background: #FFFFFF;
  padding: 20px 10px;
  border-radius: 10px;
}

.offer-payment #payment-message {
  color: #FFFFFF;
  font-size: 13px;
  text-align: center;
  padding-top: 5px;
}

.offer-payment button {
  background: #3E42F9;
  border-radius: 4px;
  padding: 10px;
  font-family: 'Anton';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.115em;
  text-transform: uppercase;
}

.offer-payment-disclaimer {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  padding: 0 15px;
  margin-bottom: 50px;
}

.cta-welcome {
  background: #0B115A;
  border-radius: 16px;
  padding: 56px 40px;
  text-align: center;
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
}

.cta-welcome-mark {
  background: #317BFA;
  color: #FFFFFF;
  width: 110px !important;
  height: 110px !important;
  border-radius: 110px;
  padding: 1px;
  margin-bottom: 46px;
}

.cta-welcome-caption {
  margin-bottom: 30px;
}

.cta-welcome-caption span {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid #317BFA;
}

.cta-welcome-apps {
  margin-top: 30px;
}

.cta-welcome-apps a {
  margin: 0 15px;
}

.cta-total {
  font-family: 'Anton';
  font-style: normal;
  color: #FFFFFF;
}

.cta-total-today {
  font-weight: 700;
  font-size: 24px;
  border-bottom: 1px solid #FFFFFF;
}

.cta-total-sum {
  text-align: right;
}

.cta-total-after {
  font-weight: 400;
  font-size: 18px;
  color: #C9C9C9;
  padding-top: 10px;
}

.cta-mobile-stepper {
  display: none !important;
}

.offer-section {
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 1128px) {
  .cta-welcome {
    background: #06092F;
    padding: 6px 10px;
  }

  .cta-welcome-mark {
    margin-top: 25px;
  }

  .cta-welcome-caption span {
    font-family: 'Anton';
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    border: none;
    text-transform: none;
  }

  .cta-welcome-apps a {
    margin: 0 5px;
  }

  .cta-welcome-apps img {
    width: 140px;
  }

  .cta-mobile-stepper {
    display: flex !important;
    background-color: transparent !important;
    padding: 24px 0px !important;
  }

  .cta-mobile-stepper svg {
    color: #FFFFFF !important;
  }

  .cta-mobile-stepper text {
    fill: #06092F !important;
    font-weight: 700;
    font-size: 13px !important;
  }

  .cta-mobile-stepper .inactive svg {
    color: #06092F !important;
    border: 1px solid #808080;
    border-radius: 50px;
  }

  .cta-mobile-stepper .inactive text {
    fill: #808080 !important;
    font-weight: 700;
    font-size: 13px !important;
  }

  .cta-caption {
    font-size: 42px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .cta-caption span {
    display: inline-block;
    margin-left: 5px;
  }

  .offer-roadmap,
  .offer-block-caption,
  .cta-subcaption,
  .cta-benefits,
  .cta-total {
    display: none !important;
  }

  .offer-product {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .offer-product.special {
    padding-top: 45px;
  }

  .offer-product-name {
    font-size: 14px;
  }

  .offer-product-price {
    font-size: 24px;
    padding: 0;
  }

  .offer-product-billed {
    font-size: 14px;
  }

  .offer-block-caption-wrapper {
    padding-bottom: 0;
  }

  .offer-section {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.cta-caption {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 900;
  font-size: 80px;
  line-height: 90%;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 32px;
  margin-top: 60px;
}

.cta-caption span {
  color: #317BFA;
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
}

.cta-subcaption {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 176.69%;
  color: #FFFFFF;
}

.cta-invitational {
  padding-top: 50px;
}

.cta-invitational-benefits-wrapper {
  padding-bottom: 140px;
}

.cta-invitational-benefits {
  color: #FFFFFF;
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 176.69%;
  padding-top: 40px;  
  list-style: none;
}

.cta-invitational-benefits li {
  margin-bottom: 10px;
}

.cta-benefit-mark {
  vertical-align: middle;
  background-color: #580CE3;
  border-radius: 1em;
  padding: 1px;
  margin-right: 5px;
}

.offer-roadmap {
  padding-bottom: 50px;
  justify-content: space-between;
}

.offer-active-step {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
}

.offer-active-step span {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  background-color: #FFFFFF;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  padding: 3px 0;
  margin-top: 1px;
}


.offer-inactive-step {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #808080;
}

.offer-inactive-step span {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #808080;
  border: 1px solid #808080;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  padding: 2px 0;
  margin-top: 1px;
}

.offer-signup {
  padding-bottom: 30px;
}

.offer-block-caption-wrapper {
  text-align: center;
  padding-bottom: 30px;
}

.offer-block-caption {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  text-transform: uppercase;
  color: #FFFFFF;
  border-bottom: 4px solid #580CE3;
  padding: 0 10px;
}

.offer-text {
  color: #FFFFFF;
}

a.offer-link {
  color: #FFFFFF;
  text-decoration: underline;
}

a.offer-link:hover {
  text-decoration: none;
}

.offer-input div {
  background-color: #FFFFFF;
}

.offer-input legend,
.offer-input label[data-shrink="true"] {
  display: none;
}

button.offer-button {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.115em;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #3E42F9;
  border-radius: 4px;
  padding: 12px 36px;
}

button.offer-button:disabled {
  color: rgba(255, 255, 255, 0.26);
  background: rgba(62, 66, 249, 0.91);
}

.offer-product {
  background: #0B115A;
  border: 5px solid #0B115A;
  border-radius: 16px;
  padding: 45px 30px;
  font-family: 'Anton';
  font-style: normal;
  color: #C9C9C9;
  margin-bottom: 24px;
  cursor: pointer;
  position: relative;
}

.offer-product.special {
  border-color: #317BFA;
}

.offer-product-cta {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 8px 18px 8px 16px;
  grid-gap: 8px;
  gap: 8px;
  position: absolute;
  left: 0px;
  top: 0px;
  background: #317BFA;
  border-radius: 8px 0px 8px;
}

.offer-product.active,
.offer-product:hover {
  color: #FFFFFF;
}

.offer-product-name {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.offer-product-price {
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  padding-top: 16px;
  padding-bottom: 8px;
}

.offer-product-billed {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.offer-product-check-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}

.offer-product-check {
  background-color: #0B115A;
  color: #0B115A;
  width: 33px !important;
  height: 33px !important;
  border-radius: 33px;
  padding: 1px;
  border: 3px solid #C9C9C9;
}

.offer-product.active .offer-product-check {
  background-color: #317BFA;
  color: #FFFFFF;
  border: 3px solid #317BFA;
}

.offer-payment .stripe-card {
  background: none;
  padding-top: 15px;
}

.offer-payment .StripeElement {
  background: #FFFFFF;
  padding: 20px 10px;
  border-radius: 10px;
}

.offer-payment #payment-message {
  color: #FFFFFF;
  font-size: 13px;
  text-align: center;
  padding-top: 5px;
}

.offer-payment button {
  background: #3E42F9;
  border-radius: 4px;
  padding: 10px;
  font-family: 'Anton';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.115em;
  text-transform: uppercase;
}

.offer-payment-disclaimer {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  padding: 0 15px;
  margin-bottom: 50px;
}

.cta-welcome {
  width: 100%;
  background: #0B115A;
  border-radius: 16px;
  padding: 56px 40px;
  text-align: center;
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
}

.cta-welcome-mark {
  background: #317BFA;
  color: #FFFFFF;
  width: 110px !important;
  height: 110px !important;
  border-radius: 110px;
  padding: 1px;
  margin-bottom: 46px;
}

.cta-welcome-caption {
  margin-bottom: 30px;
}

.cta-welcome-caption span {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid #317BFA;
}

.cta-welcome-apps {
  margin-top: 30px;
}

.cta-welcome-apps a {
  margin: 0 15px;
}

.cta-total {
  font-family: 'Anton';
  font-style: normal;
  color: #FFFFFF;
}

.cta-total-today {
  font-weight: 700;
  font-size: 24px;
  border-bottom: 1px solid #FFFFFF;
}

.cta-total-sum {
  text-align: right;
}

.cta-total-after {
  font-weight: 400;
  font-size: 18px;
  color: #C9C9C9;
  padding-top: 10px;
}

.cta-mobile-stepper {
  display: none !important;
}

.offer-section {
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 1128px) {
  .cta-welcome {
    background: #06092F;
    padding: 6px 10px;
  }

  .cta-welcome-mark {
    margin-top: 25px;
  }

  .cta-welcome-caption span {
    font-family: 'Anton';
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    border: none;
    text-transform: none;
  }

  .cta-welcome-apps a {
    margin: 0 5px;
  }

  .cta-welcome-apps img {
    width: 140px;
  }

  .cta-mobile-stepper {
    display: flex !important;
    background-color: transparent !important;
    padding: 24px 0px !important;
  }

  .cta-mobile-stepper svg {
    color: #FFFFFF !important;
  }

  .cta-mobile-stepper text {
    fill: #06092F !important;
    font-weight: 700;
    font-size: 13px !important;
  }

  .cta-mobile-stepper .inactive svg {
    color: #06092F !important;
    border: 1px solid #808080;
    border-radius: 50px;
  }

  .cta-mobile-stepper .inactive text {
    fill: #808080 !important;
    font-weight: 700;
    font-size: 13px !important;
  }

  .cta-caption {
    font-size: 42px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .cta-caption span {
    display: inline-block;
    margin-left: 5px;
  }

  .cta-invitational-benefits-wrapper {
    padding-bottom: 0;
  }

  .offer-roadmap,
  .offer-block-caption,
  .cta-subcaption,
  /*.cta-invitational-benefits,*/
  .cta-total {
    display: none !important;
  }

  .cta-invitational {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .cta-invitational img {
    max-width: 100%;
  }

  .offer-product {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .offer-product.special {
    padding-top: 45px;
  }

  .offer-product-name {
    font-size: 14px;
  }

  .offer-product-price {
    font-size: 24px;
    padding: 0;
  }

  .offer-product-billed {
    font-size: 14px;
  }

  .offer-block-caption-wrapper {
    padding-bottom: 0;
  }

  .offer-section {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.cta-caption {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 80px;
  line-height: 90%;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 32px;
  margin-top: 60px;
}

.speedAssessment .cta-caption span {
  color: #317BFA;
  display: inline;
}

.cta-subcaption {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 176.69%;
  color: #FFFFFF;
}

.coach-avatar img {
  border-radius: 20px;
  margin-top: 60px;
}

.cta-benefits {
  color: #FFFFFF;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 176.69%;
  padding-top: 40px;
  padding-bottom: 210px;
  list-style: none;
}

.speedAssessment .cta-benefits {
  padding-bottom: 80px;
}

.cta-benefits li {
  margin-bottom: 10px;
}

.speedAssessment .cta-benefits li {
  align-items: start;
}

.cta-benefit-mark {
  vertical-align: middle;
  background-color: #580CE3;
  border-radius: 1em;
  padding: 1px;
  margin-right: 5px;
}

.offer-roadmap {
  padding-bottom: 50px;
  justify-content: space-between;
}

.speedAssessment .offer-toggle-group {
  display: block;
}

.speedAssessment .offer-toggle {
  color: #FFFFFF;
  border: 1px solid #FFFFFF !important;
  border-radius: 4px !important;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 9px 18px;
}

.speedAssessment .offer-toggle.active {
  color: #FFFFFF;
  background-color: #580CE3;
  border: 1px solid transparent !important;
}

.offer-active-step {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
}

.offer-active-step span {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  background-color: #FFFFFF;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  padding: 3px 0;
  margin-top: 1px;
}


.offer-inactive-step {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #808080;
}

.offer-inactive-step span {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #808080;
  border: 1px solid #808080;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  padding: 2px 0;
  margin-top: 1px;
}

.offer-signup {
  padding-bottom: 30px;
}

.offer-block-caption-wrapper {
  text-align: center;
  padding-bottom: 30px;
}

.offer-block-caption {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  text-transform: uppercase;
  color: #FFFFFF;
  border-bottom: 4px solid #580CE3;
  padding: 0 10px;
}

.offer-text {
  color: #FFFFFF;
}

a.offer-link {
  color: #FFFFFF;
  text-decoration: underline;
}

a.offer-link:hover {
  text-decoration: none;
}

.offer-input div {
  background-color: #FFFFFF;
}

.offer-input legend,
.offer-input label[data-shrink="true"] {
  display: none;
}

button.offer-button {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.115em;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #3E42F9;
  border-radius: 4px;
  padding: 12px 36px;
}

button.offer-button:disabled {
  color: rgba(255, 255, 255, 0.26);
  background: rgba(62, 66, 249, 0.91);
}

.offer-product {
  background: #0B115A;
  border: 5px solid #0B115A;
  border-radius: 16px;
  padding: 45px 30px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  color: #C9C9C9;
  margin-bottom: 24px;
  cursor: pointer;
  position: relative;
}

.offer-product.special {
  border-color: #317BFA;
}

.offer-product-cta {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 8px 18px 8px 16px;
  grid-gap: 8px;
  gap: 8px;
  position: absolute;
  left: 0px;
  top: 0px;
  background: #317BFA;
  border-radius: 8px 0px 8px;
}

.offer-product.active,
.offer-product:hover {
  color: #FFFFFF;
}

.offer-product-name {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.offer-product-price {
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  padding-top: 16px;
  padding-bottom: 8px;
}

.offer-product-billed {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.offer-product-check-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}

.offer-product-check {
  background-color: #0B115A;
  color: #0B115A;
  width: 33px !important;
  height: 33px !important;
  border-radius: 33px;
  padding: 1px;
  border: 3px solid #C9C9C9;
}

.offer-product.active .offer-product-check {
  background-color: #317BFA;
  color: #FFFFFF;
  border: 3px solid #317BFA;
}

.offer-payment .stripe-card {
  background: none;
  padding-top: 15px;
}

.offer-payment .StripeElement {
  background: #FFFFFF;
  padding: 20px 10px;
  border-radius: 10px;
}

.offer-payment #payment-message {
  color: #FFFFFF;
  font-size: 13px;
  text-align: center;
  padding-top: 5px;
}

.offer-payment button {
  background: #3E42F9;
  border-radius: 4px;
  padding: 10px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.115em;
  text-transform: uppercase;
}

.offer-payment-disclaimer {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  padding: 0 15px;
  margin-bottom: 50px;
}

.cta-welcome {
  background: #0B115A;
  border-radius: 16px;
  padding: 56px 40px;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
}

.cta-welcome-mark {
  background: #317BFA;
  color: #FFFFFF;
  width: 110px !important;
  height: 110px !important;
  border-radius: 110px;
  padding: 1px;
  margin-bottom: 46px;
}

.cta-welcome-caption {
  margin-bottom: 30px;
}

.cta-welcome-caption span {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid #317BFA;
}

.cta-welcome-apps {
  margin-top: 30px;
}

.cta-welcome-apps a {
  margin: 0 15px;
}

.cta-total {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  color: #FFFFFF;
}

.cta-total-today {
  font-weight: 700;
  font-size: 24px;
  border-bottom: 1px solid #FFFFFF;
}

.cta-total-sum {
  text-align: right;
}

.cta-total-sum s {
  font-weight: 400;
}

.cta-total-after {
  font-weight: 400;
  font-size: 18px;
  color: #C9C9C9;
  padding-top: 10px;
}

.cta-mobile-stepper {
  display: none !important;
}

.offer-section {
  padding-left: 50px;
  padding-right: 50px;
}

.speedAssessment .address-autocomplete div[class$="-menu"] {
  z-index: 999;
}

.gif-v2 {
  display: none !important;
}

@media (max-width: 1128px) {
  .cta-welcome {
    background: #06092F;
    padding: 6px 10px;
  }

  .cta-welcome-mark {
    margin-top: 25px;
  }

  .cta-welcome-caption span {
    font-family: 'Lato', sans-serif;
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    border: none;
    text-transform: none;
  }

  .cta-welcome-apps a {
    margin: 0 5px;
  }

  .cta-welcome-apps img {
    width: 140px;
  }

  .cta-mobile-stepper {
    display: flex !important;
    background-color: transparent !important;
    padding: 24px 0px !important;
  }

  .cta-mobile-stepper svg {
    color: #FFFFFF !important;
  }

  .cta-mobile-stepper text {
    fill: #06092F !important;
    font-weight: 700;
    font-size: 13px !important;
  }

  .cta-mobile-stepper .inactive svg {
    color: #06092F !important;
    border: 1px solid #808080;
    border-radius: 50px;
  }

  .cta-mobile-stepper .inactive text {
    fill: #808080 !important;
    font-weight: 700;
    font-size: 13px !important;
  }

  .cta-caption {
    font-size: 42px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .cta-caption span {
    display: inline-block;
    margin-left: 5px;
  }

  .offer-roadmap,
  .offer-block-caption,
  .gif {
    display: none !important;
  }

  .cta-subcaption,
  .cta-total,
  .cta-benefits,
  .gif-v2 {
    display: block !important;
    padding-bottom: 5%;
  }

  .cta-invitational {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .cta-invitational img {
    max-width: 100%;
  }

  .offer-product {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .offer-product.special {
    padding-top: 45px;
  }

  .offer-product-name {
    font-size: 14px;
  }

  .offer-product-price {
    font-size: 24px;
    padding: 0;
  }

  .offer-product-billed {
    font-size: 14px;
  }

  .offer-block-caption-wrapper {
    padding-bottom: 0;
  }

  .offer-section {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.style_tableContainer__2dIbD {
  font-family: "Manrope", sans-serif;
  margin-top: 10px;
}

.style_headerCell__1j8gU {
  font-weight: bold;
  background-color: #f9fafb;
}

.style_status__2Z3_o {
  padding: 2px 10px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-gap: 10px;
  gap: 10px;
  text-transform: capitalize;
}

.style_pending__3FN8D {
  color: #175cd3;
  background: #eff8ff;
}

.style_complete__3oypr {
  color: #027a48;
  background: #ecfdf3;
}

.style_refund__1P-Fh {
  background: #f2f4f7;
  color: #344054;
}

.style_cancelled__1OB_J {
  background: #fef3f2;
  color: #b42318;
}

.style_closeButton__2xee4 {
  position: absolute;
  right: 10px;
  top: 10px;
}

/* .pagination {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.paginationButton {
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: #f2f4f7;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
  color: #344054;
}
 */

.style_paginationContainer__2Czr2 {
  display: flex;
  justify-content: space-between;
  padding: 14px 24px 18px;
  border-left: #eaecf0 1px solid;
  border-right: #eaecf0 1px solid;
  border-bottom: #eaecf0 1px solid;
  border-radius: 0 0 12px 12px;
}

.style_paginationButton__5wLhq {
  background-color: #fff;
  border-radius: 64px;
  padding: 8px 14px 8px 14px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  color: #344054;
  font-weight: 600;
  cursor: pointer;
}

.style_activePaginationButton__3jaik {
  background-color: #f9fafb;
  border-radius: 64px;
  padding: 8px 14px 8px 14px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  color: #344054;
  font-weight: 600;
  cursor: pointer;
}

.style_purchaseHistoryButton__3siyX {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  cursor: pointer;
  background-color: #f2f4f7;
  border: none;
  border-radius: 64px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.styles_dashboardButton__3oEsQ {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.styles_dashboardButton__3oEsQ:disabled {
  background-color: #f2f4f7;
  color: gray;
  cursor: not-allowed;
}

.styles_blue__3RL3m {
  background-color: #4c59cc;
  color: white;
}

.styles_gray__U6fx3 {
  background-color: #f2f4f7;
  color: black;
}

.styles_red__Q6j97 {
  background-color: #d92d20;
  color: white;
}

.styles_dashboardButton__3oEsQ.styles_fit__2ik9n {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.styles_card__3bTMi {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 320px;
  min-height: 244px;
  padding: 24px;
  border: 1px solid #eaecf0;
  border-radius: 25px;
  box-shadow: 0px 0px 27px -3px rgba(234, 236, 240, 1);
}

.styles_table-container__3bHcr {
  border-radius: 10px 10px 10px 10px;
  overflow-x: auto;
}

.styles_styled-table__8ePf7 {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0px 0px 27px -3px rgba(234, 236, 240, 1);
}

.styles_styled-table__8ePf7 th,
.styles_styled-table__8ePf7 td {
  padding: 16px 24px;
  text-align: left;
  border: 1px solid #eaecf0;
}

.styles_styled-table__8ePf7 th {
  background-color: #eaecf0;
  border: 1px solid #eaecf0;
}

/* Pagination styles */
.styles_pagination__1uV9i {
  display: flex;
  justify-content: space-between;
}

.styles_pagination-button__q_WDn {
  padding: 10px;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  font-weight: bold;
  background-color: white;
  border: none;
}

.styles_pagination-items__1k3sp {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}

.styles_page__2o3dD {
  background: white;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.styles_page__2o3dD:hover {
  background: #f9fafb;
}

.styles_activePage__TS0JO {
  background: #f9fafb;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
}

.styles_tableStatusSuccess__29lhI {
  background-color: #ecfdf3;
  color: #027a48;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: 500;
}

.styles_tableStatusPending__1795J {
  background-color: #eaecf0;
  color: gray;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: 500;
}

.styles_tableDaysLeft__N-GFe {
  color: #b54708;
  border: 2px solid #b54708;
  padding: 2px 2px;
  border-radius: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-width: 100px;
}

.styles_tableDaysLeftGreen__3LhHx {
  color: #027a48;
  border: 2px solid #027a48;
  padding: 2px 2px;
  border-radius: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-width: 100px;
}

.styles_popoverContainer__2yzhu {
  border: "1px solid lightgray";
}
.styles_popoverContainer__2yzhu div {
  padding: 10px;
}

.styles_cardGrayText__1wlXL {
  color: #475467;
  font-size: 14px;
}

.styles_cardGrayBoldText__24iEA {
  color: #475467;
  font-weight: bold;
  font-size: 14px;
}

.styles_cardBoldText__28Ag5 {
  font-size: 36px;
  font-weight: bold;
}

.styles_cardContainer__2NPSm {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  overflow-x: auto;
}

.styles_header__1CfOB {
  font-size: 20px;
  font-weight: bold;
  padding: 40px 0 0 0;
  display: flex;
  justify-content: space-between;
}

.styles_headerButtonGroup__2EDGz {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  padding: 20px 0;
}

.styles_renewDialogContent__1x7T4 {
  padding: 0 40px 20px;
}

.styles_renewDialogButtons__uf0v8 {
  padding: 10px 40px;
  display: flex;
  justify-content: space-evenly;
}

.styles_subscriptionFilterContainer__2hA70 {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.styles_subscriptionFilterContainer__2hA70 div {
  padding: 10px 20px;
  color: #667085;
  cursor: pointer;
}

.styles_subscriptionFilterContainer__2hA70 .styles_selected__3KmN_ {
  background-color: #fafafa;
  color: black;
  font-weight: bold;
}

.styles_container__1rvuj {
  display: flex;
  flex-direction: column;
  width: 200px;
}

.styles_selectWrapper__3P89I {
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  border: 1px solid #d0d5dd;
  border-radius: 7px;
  padding: 0 12px;
  background-color: white;
  cursor: pointer;
  font-size: 16px;
}

.styles_selectValue__1Pe8N {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.styles_selectValue__1Pe8N span {
  font-size: 14px;
}

.styles_selectLabel__qCR_l {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.styles_options__2ihTq {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  font-size: 14px;
  background-color: white;
  border: 1px solid #d0d5dd;
  border-radius: 7px;
  z-index: 100000000000;
  display: none;
  margin-top: 4px;
  max-height: 300px;
  overflow-y: auto;
}

.styles_optionsVisible__wQioL {
  display: block;
}

.styles_option__3eBpw {
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.styles_checkedOption__3x8FE {
  padding: 8px 12px;
  cursor: pointer;
  background-color: #f0f0f0;
}

.styles_checkedOption__3x8FE:hover {
  background-color: white;
}

.styles_option__3eBpw:hover {
  background-color: #f0f0f0;
}

.styles_inputLabel__2z1i8 {
  color: #344054;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.styles_inputContainer__AS5en {
  border: #d0d5dd 1px solid;
  background-color: white;
  border-radius: 7px;
  color: #667085;
  display: flex;
  align-items: center;
  padding: 2px 10px;
  grid-gap: 10px;
  gap: 10px;
  box-sizing: border-box;
}

.styles_inputContainer__AS5en input {
  border: none;
  flex-grow: 1;
  width: 100%;
  outline: none;
  height: 14px;
  padding: 14px 0;
  background: rgba(0, 0, 0, 0);
}

.styles_inputContainer__AS5en div {
  margin-top: 5px;
  cursor: pointer;
}

.styles_inputLabel__3FQ6E {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.styles_dialogContent__OK_lS {
  padding: 0 40px 20px;
}

.styles_dialogTitle__2sTLI {
  display: flex;
  justify-content: space-between;
}

.styles_dialogHeader__2RsaP {
  font-weight: bold;
  font-size: 18px;
}

.styles_dialogButtonGroup__2A0co {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
}

.styles_dialogInputGroup__SWOd5 {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

.styles_dialogCheckboxGroup__R7HU0 {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.styles_dialogTitleIcon__2z8LS {
  color: #344054;
  font-size: 20px;
  border: #eaecf0 1px solid;
  border-radius: 10px;
  padding: 10px 12px;
}

.styles_container__1hT1g {
  display: flex;
  flex-direction: column;
}

.styles_selectWrapper__kvIJW {
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  border: 1px solid #d0d5dd;
  border-radius: 7px;
  padding: 0 12px;
  background-color: white;
  cursor: pointer;
  font-size: 16px;
}

.styles_selectValue__2G_yX {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.styles_selectValue__2G_yX span {
  font-size: 14px;
}

.styles_selectLabel__Wl44I {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.styles_options__1LSfK {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  font-size: 14px;
  background-color: white;
  border: 1px solid #d0d5dd;
  border-radius: 7px;
  z-index: 100000000000;
  display: none;
  margin-top: 4px;
  max-height: 300px;
  overflow-y: auto;
}

.styles_optionsVisible__3B44X {
  display: block;
}

.styles_option__e2_8- {
  padding: 8px 12px;
  cursor: pointer;
}

.styles_option__e2_8-:hover {
  background-color: #f0f0f0;
}

.styles_inputLabel__2izrp {
  color: #344054;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.styles_renewDialogContent__1Jezt {
  padding: 0 40px 20px;
}

.styles_renewDialogButtons__1dTpK {
  padding: 10px 40px;
  display: flex;
  justify-content: space-evenly;
}

.styles_renewDialogContent__3CTJ9 {
  padding: 0 40px 20px;
}

.styles_renewDialogButtons__1onso {
  padding: 10px 40px;
  display: flex;
  justify-content: space-evenly;
}

.styles_buttonGroup__1id-I {
  display: flex;
  justify-content: end;

  grid-gap: 20px;

  gap: 20px;
  padding: 10px 20px 20px;
}

.styles_headerContainer__3Jdj5 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.styles_header__qmPDn {
  font-weight: bold;
  font-size: 20px;
  color: #475467;
  display: flex;
  justify-content: space-between;
}

.styles_subText__P0jGB {
  color: #475467;
  font-size: 16px;
}

.styles_layout__2aVu6 {
  display: flex;
  padding: 20px 20px 10px;
  width: 100%;
  white-space: pre-wrap;
  /* gap: 20px; */
}

.styles_icon__3xnEe {
  color: #d92d20;
  font-size: 30px !important;
}

.styles_iconBackground__3i2V9 {
  border-radius: 48%;
  background-color: #fee4e2;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px;
}

.cta-welcome {
  background: #0B115A;
  border-radius: 16px;
  padding: 56px 40px;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  margin: auto; /* Center horizontally */
}

.cta-welcome-mark {
  background: #317BFA;
  color: #FFFFFF;
  width: 110px !important;
  height: 110px !important;
  border-radius: 110px;
  padding: 1px;
  margin-bottom: 46px;
}

.cta-welcome-caption {
  margin-bottom: 30px;
}

.cta-welcome-caption span {
  font-family: 'Saira Extra Condensed';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid #317BFA;
}

.cta-welcome-apps {
  margin-top: 30px;
}

.cta-welcome-apps a {
  margin: 0 15px;
}
.page_header__2SPu9 {
  padding: 40px;
}

.page_header__2SPu9 h1 {
  font-size: 24px;
  font-weight: 600;
}

.page_header__2SPu9 h2 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 40px;
}

.tabs_tabContainer__35sBQ {
  padding: 40px 0;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.tabs_tab__2gLlA {
  padding: 10px;
  border-radius: 18px;
  cursor: pointer;
}

.tabs_selectedTab__2BToc {
  background-color: #f9fafb;
}

.change_container__2SjOO {
  padding: 30px;
  border-radius: 20px;
  box-shadow: 1px 1px 10px -7px rgba(0, 0, 0, 1);
  border: 1px solid #eaecf0;
}

.change_segmentContainer__1cjYf {
  display: flex;
}

.change_mobileSegmentItem__1R5NY {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.change_segmentItem__2vUYb {
  display: flex;
  width: 100%;
}

.change_segmentLabel__2okfA {
  font-weight: 500;
  display: flex;
  min-width: 200px;
  width: 20%;
  padding: 10px 0;
}

.change_segmentColumn__1AAk5 {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 0;
}

.change_segmentContent__uqZSg {
  width: 100%;
}

.change_pictureUploadContainer__36ZS8 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.change_pictureUpload__2egOQ {
  cursor: pointer;
  width: 100%;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
}

.change_mobileSegmentLabel__8bI57 {
  font-weight: 500;
  display: flex;
  min-width: 120px;
  width: 20%;
  padding: 10px 0;
}

.change_imageContainer__2PUhp {
  max-width: 150px;
  max-height: 150px;
  min-width: 150px;
  min-height: 150px;
  /* overflow: hidden;  */
  /* position: relative;  */
  box-shadow: 1px 1px 10px -7px rgba(0, 0, 0, 1);
  border: 3px solid white;
  border-radius: 50%;
}

.change_imageItem__1QyTC {
  width: 150%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.change_mobileImageContainer__231S4 {
  max-width: 120px;
  max-height: 120px;
  min-width: 120px;
  min-height: 120px;
  /* overflow: hidden;  */
  /* position: relative;  */
  box-shadow: 1px 1px 10px -7px rgba(0, 0, 0, 1);
  border: 3px solid white;
  border-radius: 50%;
}

.change_dropzone__DHr-a {
  width: 100%;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 1px 1px 10px -7px rgba(0, 0, 0, 1);
  border: 1px solid #eaecf0;
}

.change_dropzoneContent__2zEjY {
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.styles_container__3JrP8 {
  padding: 30px;
  border-radius: 20px;
  box-shadow: 1px 1px 10px -7px rgba(0, 0, 0, 1);
  border: 1px solid #eaecf0;
}

.styles_segmentColumn__1AqF2 {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 0;
}

.styles_segmentLabel__q0vmk {
  display: flex;
  width: 1000px;
}

.styles_segmentItem__1GUKi {
  font-weight: 500;
  display: flex;
}

.styles_buttonGroup__oI4_R {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
}

.styles_planHeader__2Ntyp {
  font-size: 34px;
  font-weight: 500;
  padding: 15px 0;
}

.styles_cardNumber__1WdpS {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.styles_divider__1RQP5 {
  height: 20px;
}

.styles_flexContainer__27492 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
  overflow-y: none;
  overflow-x: auto;
}

.styles_shadowContainer__2FnHo {
  box-shadow: 1px 1px 10px -7px rgba(0, 0, 0, 1);
  border: 1px solid #eaecf0;
  padding: 20px;
  border-radius: 20px;
}

.styles_container__3HgxV {
  border-radius: 20px;
  box-shadow: 1px 1px 10px -7px rgba(0, 0, 0, 1);
  border: 1px solid #eaecf0;
  min-width: 268px;
  width: 49%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.styles_paddingContainer__o3xFI {
  padding: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.styles_type__2d_Bm {
  color: #30077c;
  font-size: 20px;
  font-weight: 500;
}

.styles_header__UxpKs {
  font-size: 30px;
  font-weight: 500;
}

.styles_subtext__2GE72 {
  font-size: 16px;
  color: #475467;
  font-weight: 500;
}

.styles_grayBg__1OpFD {
  margin-top: auto;
  background: #f9fafb;
  height: 112px;
  width: 100%;
  padding: 35px;
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.styles_greenText__cAOSX {
  color: #027a48;
  display: flex;
}

.styles_subItemContainer__1WKJp {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}

.styles_subItem__16uID {
  display: flex;
  font-weight: 500;
  font-size: 16px;
  color: #475467;
}

.styles_subItemIcon__1ctHS {
  background-color: #e9ddfd;
  border-radius: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  margin-right: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.styles_iconContainer__3z5TZ {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 40px;
  cursor: pointer;
}

.styles_switchContainer__1VgqJ {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 0 30px 0;
}

.styles_addonContainer__QfSoB {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 3px solid #30077c;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
}

.styles_addonTag__2Q8U5 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 20px;
  padding: 5px 10px;
  border: 2px solid #30077c;
  color: #30077c;
  font-weight: 500;
}

.styles_addonTextContainer__HG97M {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #344054;
  font-weight: 500;
}

.styles_importantBox__8V_eh {
  padding: 10px;
  border: 1px solid #9662f7;
  background-color: rgba(150, 98, 247, 0.1);
  border-radius: 10px;
  color: #30077c;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  width: 65%;
  font-weight: 600;
}

.styles_container__2VdaD {
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
}

.styles_infoBox__1rvtp {
  padding: 20px;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  width: 65%;
}

@media (max-width: 1024px) {
  .styles_infoBox__1rvtp {
    padding: 20px;
    border: 1px solid #d6d6d6;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
  }

  .styles_importantBox__8V_eh {
    padding: 10px;
    border: 1px solid #9662f7;
    background-color: rgba(150, 98, 247, 0.1);
    border-radius: 10px;
    color: #30077c;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
    font-weight: 600;
  }
}

.styles_bold__3j_dl {
  font-weight: 600;
}

.styles_dialogContent__1ViQK {
  padding: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.styles_dialogHeader__dGyQa {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.styles_dialogItem__1xeIi {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.styles_dialogItemNumber__2GX-B {
  background-color: #f2f4f7;
  padding: 5px 11px;
  border-radius: 50%;
}

.styles_dialogIcon__aVypc {
  color: #039855;
  background-color: #d1fadf;
  padding: 7px 7px 3px;
  border-radius: 50%;
}

.styles_dialogIconSecondary__lJEI3 {
  background-color: #ecfdf3;
  padding: 10px 10px 10px;
  border-radius: 50%;
}

.styles_dialogHeaderText__1I4dA {
  font-weight: 500;
  font-size: 24px;
}

.styles_switchBox__2fkLp {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}

.style_header__1-eHq {
  border-bottom: 1px solid #eaecf0;
}

.style_header__1-eHq h1 {
  font-size: 24px;
  font-weight: 600;
}

.style_header__1-eHq h2 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 40px;
}

.style_pageContainer__306uU {
  display: flex;
  padding: 20px;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
}

.style_content__1u0bg {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 0 20px;
  width: 100%;
  @media (min-width: 1024px) {
    width: 70%;
  }
}

.style_contentContainer__3Eun6 {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 0 20px;
}

.style_activity__2aSaf {
  width: 100%;
  border-left: 1px solid #eaecf0;
  @media (min-width: 1024px) {
    width: 30%;
  }
}

.style_container__VzsIO {
  display: flex;
  flex-direction: column;
}

.style_header__3ILbD {
  font-weight: 600;
  font-size: 20px;
  margin: 30px 0;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.style_cardsContainer__RbZj3 {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}

.style_card__3rPEu {
  padding: 24px;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  height: 120px;
  width: 180px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.style_cardPeriod__1kqDr {
  font-weight: 600;
  font-size: 14px;
  color: #475467;
}

.style_cardValue__39exA {
  font-weight: 600;
  font-size: 36px;
}

@media (max-width: 640px) {
  .style_card__3rPEu {
    padding: 10px 20px;
    border: 1px solid #eaecf0;
    border-radius: 12px;
    height: 100px;
    width: 100px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .style_cardValue__39exA {
    font-weight: 600;
    font-size: 26px;
  }
}

.style_outerContainer__xJaj1 {
  padding: 24px;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}

.style_contentContainer__15NIf {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}

.style_textContainer__3G8FK {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.style_secondContainer__1Xj-t {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.style_outerContainer__xJaj1 {
  padding: 24px;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}

@media (max-width: 1024px) {
  .style_outerContainer__xJaj1 {
    padding: 24px;
    border: 1px solid #eaecf0;
    border-radius: 12px;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
  }
}

.style_table-container__1sWrd {
  border-radius: 10px 10px 10px 10px;
  overflow-x: auto;
}

.style_styled-table__3cZPY {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0px 0px 27px -3px rgba(234, 236, 240, 1);
}

.style_styled-table__3cZPY th,
.style_styled-table__3cZPY td {
  padding: 16px 24px;
  text-align: left;
  border: 1px solid #eaecf0;
}

.style_styled-table__3cZPY th {
  background-color: #eaecf0;
  border: 1px solid #eaecf0;
}

/* Pagination styles */
.style_pagination__1Dxds {
  display: flex;
  justify-content: space-between;
}

.style_pagination-button__3qsQD {
  padding: 10px;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  font-weight: bold;
  background-color: white;
  border: none;
}

.style_pagination-items__3eGrp {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}

.style_tableStatusPending__elJwM {
  background-color: #eaecf0;
  color: gray;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: 500;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: capitalize;
}

.style_header__ptNYk {
  font-weight: 600;
  font-size: 20px;
  margin: 30px 0;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.style_popoverItemsContainer__iMeDw {
  border-radius: 10px;
  width: 240px;
}

.style_popoverItem__ldaxe {
  padding: 10px 20px;

  display: flex;
  grid-gap: 5px;
  gap: 5px;
  cursor: pointer;
}

.style_popoverItem__ldaxe:hover {
  background-color: #f2f4f7;
}

.style_activityContainer__2zUHS {
  display: flex;
  padding: 20px;
  grid-gap: 10px;
  gap: 10px;
  border-bottom: 1px solid #eaecf0;
}

.style_activityInnerContainer__W-pC0 {
  display: flex;
  flex-direction: column;
}

.style_activitySubText__Y_bjp {
  color: #737373;
  font-size: 12px;
}

.style_header__1xTQV {
  font-weight: 600;
  font-size: 20px;
  padding: 0 20px;
}

.style_scrollContainer__3Kjtt {
  display: flex;
  flex-direction: column;
  height: 75vh;
  overflow-y: auto;
}

.style_table-container__1Nk-G {
  border-radius: 10px 10px 10px 10px;
  overflow-x: auto;
}

.style_styled-table__3pK1k {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0px 0px 27px -3px rgba(234, 236, 240, 1);
}

.style_styled-table__3pK1k th,
.style_styled-table__3pK1k td {
  padding: 16px 24px;
  text-align: left;
  border: 1px solid #eaecf0;
}

.style_styled-table__3pK1k th {
  background-color: #eaecf0;
  border: 1px solid #eaecf0;
}

/* Pagination styles */
.style_pagination__2bGlX {
  display: flex;
  justify-content: space-between;
}

.style_pagination-button__1X7lM {
  padding: 10px;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  font-weight: bold;
  background-color: white;
  border: none;
}

.style_pagination-items__2l_8k {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}

.style_tableStatusPending__1hiRY {
  background-color: #eaecf0;
  color: gray;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: 500;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: capitalize;
}

.style_header__mh2c0 {
  font-weight: 600;
  font-size: 20px;
  margin: 30px 0;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.style_upgradeButton__-6eUl {
  color: #30077c;
}

.style_upgradeButton__-6eUl:hover {
  text-decoration: underline;
  cursor: pointer;
}

.style_nameColumn__2EY_y {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  border: none;
}

.style_tableDaysLeft__30so9 {
  color: #b54708;
  border: 2px solid #b54708;
  padding: 2px 2px;
  border-radius: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-width: 100px;
}

.style_tableDaysLeftGreen__2YvpA {
  color: #027a48;
  border: 2px solid #027a48;
  padding: 2px 2px;
  border-radius: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-width: 100px;
}

.styles_renewDialogContent__2Uoxp {
  padding: 0 40px 20px;
}

.styles_renewDialogButtons__1c4FO {
  padding: 10px 40px;
  display: flex;
  justify-content: space-evenly;
}

.MuiTableSortLabel-root {
  pointer-events: none; /* Disable click */
}

.MuiTableSortLabel-icon {
  display: none; /* Hide sorting arrow */
}

.styles_outerContainer__1cp-o {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.styles_container__1ZS3d {
  display: flex;
  flex-direction: column;
}

.styles_selectActive__1PVU6 {
  background-color: #e9ddfd;
  color: #30077c;
}

.styles_selectInActive__1VNrc {
  background-color: #f2f4f7;
  color: #344054;
}

.styles_selectWrapper__3O1PD {
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  border-radius: 20px;
  padding: 0 16px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: 0em;
  font-family: Manrope;
}

.styles_selectWrapper__3O1PD:hover {
  background-color: #f7f7f7;
}

.styles_selectValue__1f2IP {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.styles_selectLabel__Us6mn {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.styles_options__3HEH8 {
  margin-top: 5px;
  width: 100%;
  max-height: 432px;
  overflow-y: auto;
}

.styles_optionsLabel__8Bffs {
  padding: 16px 12px;
  font-weight: 600;
  border-bottom: #d0d5dd 1px solid;
}

.styles_optionsVisible__3pmc_ {
  display: block;
}

.styles_option__1kaKd {
  padding: 16px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.styles_option__1kaKd:hover {
  background-color: #f0f0f0;
}

.styles_inputLabel__2YE8s {
  color: #344054;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.styles_searchContainer__33Uss {
  padding: 16px 12px;
  border-bottom: #d0d5dd 1px solid;
}

.styles_loadingIcon__3Z_k0 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.styles_clearButton__2oGNQ {
  cursor: pointer;
  color: #b42318;
}

.styles_inactiveClearButton__RQgAV {
  color: gray;
  cursor: default;
}

.styles_clearButtonContainer__1s5lh {
  padding: 12px 40px 20px;
  display: flex;
  flex-direction: row-reverse;
  color: #b42318;
  font-size: 16px;
  text-align: left;
  font-family: Manrope;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
}

.styles_container__2X-wM {
  height: 100vh;
  width: 350px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2000;
  padding: 24px;
  border-left: #eaecf0 1px solid;
  background-color: white;
  font-size: 16px;
  font-weight: 500;
  font-family: Manrope;
}

.styles_header__HMY27 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}

.styles_tagList__2y-LC {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
  margin: 16px 0;
}

.styles_tag__3URVE {
  color: #667085;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px 12px;
  cursor: pointer;
  font-weight: 600;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.styles_activeTag__wG_AG {
  background-color: #f9f5ff;
  color: #30077c;
  border-radius: 24px;
}

.styles_categories__9hvND {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  overflow-y: scroll;
  height: 65vh;
}

@media (max-height: 950px) {
  .styles_categories__9hvND {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    overflow-y: scroll;
    height: 61vh;
  }
}

.styles_categories__9hvND::-webkit-scrollbar {
  display: none;
}

.styles_tagList__2y-LC::-webkit-scrollbar {
  display: none;
}

.styles_category__3UTuh {
  display: flex;
  justify-content: space-between;
}

.styles_categoryItemsContainer__5vPom {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.styles_categoryItem__UIFNm {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  cursor: pointer;
}

.styles_buttonsContainer__1nEK- {
  display: flex;
  justify-content: space-between;
  padding: 26px 0;
  border-top: #eaecf0 1px solid;
  align-items: center;
}

.styles_buttonsGroup__2SL8z {
  display: flex;

  grid-gap: 8px;

  gap: 8px;
}

.styles_purpleTextButton__1WRPR {
  cursor: pointer;
  color: #30077c;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
}

.styles_cancelButton__1fHE4 {
  background-color: #f2f4f7;
  padding: 10px 16px;
  border-radius: 64px;
  border: none;
  text-transform: capitalize;
  font-size: 16px;
  cursor: pointer;
}

.styles_applyButton__2gUcv {
  background-color: #9e6cfc;
  color: white;
  padding: 10px 16px;
  border-radius: 64px;
  border: none;
  text-transform: capitalize;
  font-size: 16px;
  cursor: pointer;
}

.styles_applyButton__2gUcv:disabled {
  background-color: #cbb1fb;
}

.styles_scrollButton__3glB9 {
  background: lightgray;
  color: black;
  border-radius: 20px;
  padding: 1px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.styles_scrollContainer__3l0E0 {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

@media (max-width: 1920px) {
  .styles_scrollContainer__3l0E0 {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    width: 55vw;
  }
}
@media (max-width: 1700px) {
  .styles_scrollContainer__3l0E0 {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    width: 50vw;
  }
}

@media (max-width: 1530px) {
  .styles_scrollContainer__3l0E0 {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    width: 45vw;
  }
}

@media (max-width: 1441px) {
  .styles_scrollContainer__3l0E0 {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    width: 40vw;
  }
}

@media (max-width: 1270px) {
  .styles_scrollContainer__3l0E0 {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    width: 35vw;
  }
}

.styles_overflowContainer__2guzK {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
}

.styles_overflowContainer__2guzK::-webkit-scrollbar {
  display: none;
}

.styles_container__1GIHj {
  display: flex;
  flex-direction: column;
  position: relative;
}

.styles_selectWrapper__u_cvs {
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  border: 1px solid #d0d5dd;
  border-radius: 7px;
  padding: 0 12px;
  background-color: white;
  cursor: pointer;
  font-size: 16px;
}

.styles_selectValue__2Gvv3 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.styles_selectValue__2Gvv3 span {
  font-size: 14px;
}

.styles_selectLabel__2Sn9y {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

/* .options {
  position: absolute;
  top: 100%;
  left: 0;

  width: 100%;
  font-size: 14px;
  background-color: white;
  border: 1px solid #d0d5dd;
  border-radius: 7px;
  z-index: 100000000000 !important;
  display: none;
  margin-top: 4px;
  max-height: 300px;
  overflow-y: auto;
} */

.styles_optionsContainer__33lv3 {
  border: 1px solid #d0d5dd;
  border-radius: 7px;
  margin-top: 5px;
  position: absolute;
  background: white;
  width: 100%;
  z-index: 1000;
  top: 100%;
}

.styles_optionsLabel__2N_zd {
  margin: 10px;
  display: flex;
  justify-content: space-between;
}

.styles_options__1VMaa {
  width: 100%;
  height: 300px;
  overflow-y: scroll;
}

.styles_optionsVisible__2Typb {
  display: block;
}

.styles_option__299YC {
  padding: 8px 12px;
  cursor: pointer;
}

.styles_option__299YC:hover {
  background-color: #f0f0f0;
}

.styles_inputLabel__1fw-v {
  color: #344054;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.styles_clearButton__27Yr_ {
  color: red;
  cursor: pointer;
}

/* .tableHead {
  width: 100%;
  background: violet;
  cursor: pointer;
  display: flex;
  user-select: none;
} */

.SwingTable_stickyHeader__2-zez {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  border: none;
}

.SwingTable_stickyAvg__3KzAm {
  position: -webkit-sticky;
  position: sticky;
  z-index: 20;
  border-collapse: collapse;
}

.SwingTable_stickyStd__3ln3I {
  position: -webkit-sticky;
  position: sticky;
  z-index: 20;
  border-collapse: collapse;
}

thead th {
  background-color: #f9fafb;
  color: #475467;
  padding: 8px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: start;
  font-weight: 500;
}

th,
td {
  padding: 8px;
  background: #fff;
  border-top: 1px solid #eaecf0;
  border-bottom: 1px solid #eaecf0;
}

tr {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-radius: 20px;
  width: 100%;
  border-left: #eaecf0 1px solid;
  border-right: #eaecf0 1px solid;
  /*user-select: none;*/
}

.SwingTable_tableStdAndAvg__2xrDC {
  color: #101828;
  font-weight: 600;
}

.SwingTable_textLeft__2AtIA {
  text-align: end;
}

.SwingTable_tableStd__2sbEV {
  background-color: #eff8ff;
}

.SwingTable_tableAvg__EHNx6 {
  background-color: #f2f4f7;
}

.SwingTable_tableWrapper__wP2Q- {
  border: 1px solid #eaecf0;
  border-radius: 20px;
  padding: 20px 0;
}

.SwingTable_paginationContainer__3OYCJ {
  display: flex;
  justify-content: space-between;
  padding: 14px 24px 18px;
  border-left: #eaecf0 1px solid;
  border-right: #eaecf0 1px solid;
  border-bottom: #eaecf0 1px solid;
  border-radius: 0 0 12px 12px;
}

.SwingTable_paginationButton__3Rn_P {
  background-color: #fff;
  border-radius: 64px;
  padding: 8px 14px 8px 14px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  color: #344054;
  font-weight: 600;
  cursor: pointer;
}

.SwingTable_activePaginationButton__AGfoL {
  background-color: #f9fafb;
  border-radius: 64px;
  padding: 8px 14px 8px 14px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  color: #344054;
  font-weight: 600;
  cursor: pointer;
}

.SwingTable_sortHeaderContainer__31pXp {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  cursor: pointer;
}

.SwingTable_stdAvgContainer__3q6il {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}

.SwingTable_stdAvgSelected__2TaEH {
  color: #30077c;
  font-size: 14px;
  font-weight: 500;
}

.SwingTable_stdAvgBorderedItem__mVl97 {
  border: 1px solid #400aa5;
  border-radius: 20px;
  padding: 2px 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.SwingTable_stdAvgItemConatiner__1Cqth {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
}

.SwingTable_tooltipContainer__2Qx8d {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.SwingTable_tooltipIcon__3jMpQ {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.SwingTable_tooltipText__1bTXm {
  visibility: hidden;
  background-color: #101828;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.SwingTable_tooltipText__1bTXm {
  width: 575px;
}

@media (max-width: 767px) {
  .SwingTable_tooltipText__1bTXm {
    width: 250px;
  }
}

.SwingTable_tooltipContainer__2Qx8d:hover .SwingTable_tooltipText__1bTXm {
  visibility: visible;
  opacity: 1;
}

.style_outerContainer__2X1oX {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.style_container__qypPv {
  display: flex;
  flex-direction: column;
}

.style_selectWrapper__1dyUi {
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  border-radius: 20px;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: 0em;
  font-family: Manrope;
}

.style_selectValue__1FR9v {
  display: flex;
  justify-content: space-between;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.style_selectLabel__2cGLG {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.style_options__1hSt4 {
  margin-top: 5px;
  width: 100%;
  max-height: 432px;
  overflow-y: auto;
}

.style_optionsLabel__3Adx3 {
  padding: 16px 12px;
  font-weight: 600;
  border-bottom: #d0d5dd 1px solid;
  display: flex;
  justify-content: space-between;
}

.style_optionsVisible__3ZLS- {
  display: block;
}

.style_option__b8jBd {
  padding: 16px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.style_option__b8jBd:hover {
  background-color: #f0f0f0;
}

.style_inputLabel__1fJMt {
  color: #344054;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.style_searchContainer__3_o_J {
  padding: 16px 12px;
  border-bottom: #d0d5dd 1px solid;
}

.style_firstTag__9p779 {
  border-radius: 16px;
  background-color: #f9f5ff;
  color: #30077c;
  padding: 5px 15px;
}

.style_otherTags__3mDAH {
  background-color: #f2f4f7;
  padding: 5px 15px;
  border-radius: 14px;
  color: #344054;
}

.style_addTagButton__qT_36 {
  padding: 1px 1px;
  border-radius: 14px;
  border: 1px solid #30077c;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #30077c;
  cursor: pointer;
}

.style_clearTagButton__Iv73S {
  padding: 1px 1px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b42318;
  cursor: pointer;
}

.style_createNewTagButton__1uEAX {
  cursor: pointer;
  color: #30077c;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 15px 10px;
  /* border-top: 1px solid #d0d5dd; */
}

.style_createNewTagButton__1uEAX:hover {
  background-color: #f9f5ff;
}

.styles_outerContainer__cgZut {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.styles_container__2-8sS {
  display: flex;
  flex-direction: column;
}

.styles_selectActive__3jeu7 {
  background-color: #e9ddfd;
  color: #30077c;
}

.styles_selectInActive__19r9h {
  background-color: #f2f4f7;
  color: #344054;
}

.styles_selectWrapper__1e4-_ {
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  border-radius: 20px;
  padding: 0 16px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: 0em;
  font-family: Manrope;
}

.styles_selectWrapper__1e4-_:hover {
  background-color: #f7f7f7;
}

.styles_selectValue__23WiO {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.styles_selectLabel__1Fgr7 {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.styles_options__f88KK {
  margin-top: 5px;
  width: 100%;
  max-height: 432px;
  overflow-y: auto;
}

.styles_optionsLabel__z82RZ {
  padding: 16px 12px;
  font-weight: 600;
  border-bottom: #d0d5dd 1px solid;
}

.styles_optionsVisible__3XmOp {
  display: block;
}

.styles_option__Dyoyn {
  padding: 16px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.styles_option__Dyoyn:hover {
  background-color: #f0f0f0;
}

.styles_inputLabel__2m2oV {
  color: #344054;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.styles_searchContainer__hGp2W {
  padding: 16px 12px;
  border-bottom: #d0d5dd 1px solid;
}

.styles_loadingIcon__ajdPR {
  display: flex;
  justify-content: center;
  align-items: center;
}

.styles_clearButton__3dU_m {
  cursor: pointer;
  color: #b42318;
}

.styles_inactiveClearButton__13PLC {
  color: gray;
  cursor: default;
}

.styles_clearButtonContainer__K8czN {
  padding: 12px 40px 20px;
  display: flex;
  flex-direction: row-reverse;
  color: #b42318;
  font-size: 16px;
  text-align: left;
  font-family: Manrope;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
}

.cta-caption {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 80px;
  line-height: 90%;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 32px;
  margin-top: 60px;
}

.offerPaid .cta-caption span {
  color: #317BFA;
  display: inline;
}

.cta-subcaption {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 176.69%;
  color: #FFFFFF;
}

.coach-avatar img {
  border-radius: 20px;
  margin-top: 60px;
}

.cta-benefits {
  color: #FFFFFF;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 176.69%;
  padding-top: 40px;
  padding-bottom: 210px;
  list-style: none;
}

.offerPaid .cta-benefits {
  padding-bottom: 80px;
}

.cta-benefits li {
  margin-bottom: 10px;
}

.offerPaid .cta-benefits li {
  align-items: start;
}

.cta-benefit-mark {
  vertical-align: middle;
  background-color: #580CE3;
  border-radius: 1em;
  padding: 1px;
  margin-right: 5px;
}

.offer-roadmap {
  padding-bottom: 50px;
  justify-content: space-between;
}

.offerPaid .offer-toggle-group {
  display: block;
}

.offerPaid .offer-toggle {
  color: #FFFFFF;
  border: 1px solid #FFFFFF !important;
  border-radius: 4px !important;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 9px 18px;
}

.offerPaid .offer-toggle.active {
  color: #FFFFFF;
  background-color: #580CE3;
  border: 1px solid transparent !important;
}

.offer-active-step {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
}

.offer-active-step span {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  background-color: #FFFFFF;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  padding: 3px 0;
  margin-top: 1px;
}


.offer-inactive-step {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #808080;
}

.offer-inactive-step span {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #808080;
  border: 1px solid #808080;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  padding: 2px 0;
  margin-top: 1px;
}

.offer-signup {
  padding-bottom: 30px;
}

.offer-block-caption-wrapper {
  text-align: center;
  padding-bottom: 30px;
}

.offer-block-caption {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  text-transform: uppercase;
  color: #FFFFFF;
  border-bottom: 4px solid #580CE3;
  padding: 0 10px;
}

.offer-text {
  color: #FFFFFF;
}

a.offer-link {
  color: #FFFFFF;
  text-decoration: underline;
}

a.offer-link:hover {
  text-decoration: none;
}

.offer-input div {
  background-color: #FFFFFF;
}

.offer-input legend,
.offer-input label[data-shrink="true"] {
  display: none;
}

button.offer-button {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.115em;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #3E42F9;
  border-radius: 4px;
  padding: 12px 36px;
}

button.offer-button:disabled {
  color: rgba(255, 255, 255, 0.26);
  background: rgba(62, 66, 249, 0.91);
}

.offer-product {
  background: #0B115A;
  border: 5px solid #0B115A;
  border-radius: 16px;
  padding: 45px 30px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  color: #C9C9C9;
  margin-bottom: 24px;
  cursor: pointer;
  position: relative;
}

.offer-product.special {
  border-color: #317BFA;
}

.offer-product-cta {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 8px 18px 8px 16px;
  grid-gap: 8px;
  gap: 8px;
  position: absolute;
  left: 0px;
  top: 0px;
  background: #317BFA;
  border-radius: 8px 0px 8px;
}

.offer-product.active,
.offer-product:hover {
  color: #FFFFFF;
}

.offer-product-name {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.offer-product-price {
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  padding-top: 16px;
  padding-bottom: 8px;
}

.offer-product-billed {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.offer-product-check-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}

.offer-product-check {
  background-color: #0B115A;
  color: #0B115A;
  width: 33px !important;
  height: 33px !important;
  border-radius: 33px;
  padding: 1px;
  border: 3px solid #C9C9C9;
}

.offer-product.active .offer-product-check {
  background-color: #317BFA;
  color: #FFFFFF;
  border: 3px solid #317BFA;
}

.offer-payment .stripe-card {
  background: none;
  padding-top: 15px;
}

.offer-payment .StripeElement {
  background: #FFFFFF;
  padding: 20px 10px;
  border-radius: 10px;
}

.offer-payment #payment-message {
  color: #FFFFFF;
  font-size: 13px;
  text-align: center;
  padding-top: 5px;
}

.offer-payment button {
  background: #3E42F9;
  border-radius: 4px;
  padding: 10px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.115em;
  text-transform: uppercase;
}

.offer-payment-disclaimer {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  padding: 0 15px;
  margin-bottom: 50px;
}

.cta-welcome {
  background: #0B115A;
  border-radius: 16px;
  padding: 56px 40px;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
}

.cta-welcome-mark {
  background: #317BFA;
  color: #FFFFFF;
  width: 110px !important;
  height: 110px !important;
  border-radius: 110px;
  padding: 1px;
  margin-bottom: 46px;
}

.cta-welcome-caption {
  margin-bottom: 30px;
}

.cta-welcome-caption span {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid #317BFA;
}

.cta-welcome-apps {
  margin-top: 30px;
}

.cta-welcome-apps a {
  margin: 0 15px;
}

.cta-total {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  color: #FFFFFF;
}

.cta-total-today {
  font-weight: 700;
  font-size: 24px;
  border-bottom: 1px solid #FFFFFF;
}

.cta-total-sum {
  text-align: right;
}

.cta-total-sum s {
  font-weight: 400;
}

.cta-total-after {
  font-weight: 400;
  font-size: 18px;
  color: #C9C9C9;
  padding-top: 10px;
}

.cta-mobile-stepper {
  display: none !important;
}

.offer-section {
  padding-left: 50px;
  padding-right: 50px;
}

.offerPaid .address-autocomplete div[class$="-menu"] {
  z-index: 999;
}

.gif-v2 {
  display: none !important;
}

@media (max-width: 1128px) {
  .cta-welcome {
    background: #06092F;
    padding: 6px 10px;
  }

  .cta-welcome-mark {
    margin-top: 25px;
  }

  .cta-welcome-caption span {
    font-family: 'Lato', sans-serif;
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    border: none;
    text-transform: none;
  }

  .cta-welcome-apps a {
    margin: 0 5px;
  }

  .cta-welcome-apps img {
    width: 140px;
  }

  .cta-mobile-stepper {
    display: flex !important;
    background-color: transparent !important;
    padding: 24px 0px !important;
  }

  .cta-mobile-stepper svg {
    color: #FFFFFF !important;
  }

  .cta-mobile-stepper text {
    fill: #06092F !important;
    font-weight: 700;
    font-size: 13px !important;
  }

  .cta-mobile-stepper .inactive svg {
    color: #06092F !important;
    border: 1px solid #808080;
    border-radius: 50px;
  }

  .cta-mobile-stepper .inactive text {
    fill: #808080 !important;
    font-weight: 700;
    font-size: 13px !important;
  }

  .cta-caption {
    font-size: 42px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .cta-caption span {
    display: inline-block;
    margin-left: 5px;
  }

  .cta-invitational-benefits-wrapper {
    padding-bottom: 0;
  }

  .offer-roadmap,
  .offer-block-caption,
  .gif {
    display: none !important;
  }

  .cta-subcaption,
  .cta-total,
  .cta-benefits,
  .gif-v2 {
    display: block !important;
    padding-bottom: 5%;
  }
  .cta-invitational {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .cta-invitational-benefits-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
  }
  
  .cta-invitational-benefits {
    color: #FFFFFF;
    font-family: 'Anton';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 176.69%;
    padding-top: 40px;  
    list-style: none;
  }
  
  .cta-invitational-benefits li {
    margin-bottom: 10px;
  }

  .cta-invitational img {
    max-width: 100%;
  }

  .offer-product {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .offer-product.special {
    padding-top: 45px;
  }

  .offer-product-name {
    font-size: 14px;
  }

  .offer-product-price {
    font-size: 24px;
    padding: 0;
  }

  .offer-product-billed {
    font-size: 14px;
  }

  .offer-block-caption-wrapper {
    padding-bottom: 0;
  }

  .offer-section {
    padding-left: 8px;
    padding-right: 8px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

